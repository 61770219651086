import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";

import Slide from "@mui/material/Slide";

import Container from "@mui/material/Container";

const WhoWeAre = (props) => {
  const { whoWeAreIsVisible, id } = props;
  return (
    <Container maxWidth="xl" sx={{ backgroundColor: "#f8f8f8" }}>
      <Box sx={{ flexGrow: 1, p: 2, pt: { sm: 12 }, pb: { sm: 12 } }}>
        {/* <Container maxWidth="lg"> */}
        <Box id={id}>
          <Grid
            spacing={2}
            container
            direction="row"
            justifyContent="space-evenly"
          >
            <Grid item xs={12} lg={6}>
              <Box className="section">
                <h2 className="section-heading">Who We Are</h2>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", lg: "none" },
                  }}
                ></Grid>
                <p>
                  We at Deca provide a platform for the customers to get their
                  desired food from professional chef's and authentic cuisine
                  makers. We are three tech enthusiasts who are about to start
                  online services in Jyväskylä, Finland.
                </p>
                <p>
                  {" "}
                  Deca’s platform provides customers who crave different
                  cuisine's, which will be matched by the professional,
                  authentic cuisine makers and will be delivered at door step.
                  It can be breakfast, lunch, dinner or even a snack.
                </p>{" "}
                <p>
                  Entire delicious world will be at finger tips and one click
                  away to be at door step.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default WhoWeAre;
