import React from "react";
import { Link } from "react-router-dom";

export const Footer = (props) => {
  return (
    <div id="footer">
      <div className="footer-line"></div>

      <div class="footer-detail">
        <div class="footer-copyright">© Deca Food Tech 2022</div>
        <div class="footer-additional-links">
          <Link
            to={{
              pathname: "/security",
            }}
            // target="_blank"
            className="footer-additional-link"
          >
            Security
          </Link>
          <Link
            to={{
              pathname: "/privacy",
            }}
            // target="_blank"
            className="footer-additional-link"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};
