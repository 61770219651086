import React from "react";
import { useState, useEffect, useRef } from "react";
import logo from "../logo.svg";
import backgroundVideo from "../coverPageVideo.mp4";
import "../App.css";
import Navigation from "../components/navigation";
import WhoWeAre from "../components/whoWeAre";
import News from "../components/news";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Contact from "../components/contact";
import { Footer } from "../components/footer";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useInView } from "react-intersection-observer";

const Home = (props) => {
  const myRef = useRef();
  const contactRef = useRef();
  const whoWeAreRef = useRef();
  const [headerIsVisible, updateHeaderIsVisible] = useState(true);
  const [contactIsVisible, updateContactIsVisible] = useState(false);
  const [whoWeAreIsVisible, updateWhoWeAreIsVisible] = useState(false);
  const { data } = props;

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateHeaderIsVisible(entry.isIntersecting);
    });

    const observer1 = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateContactIsVisible(entry.isIntersecting);
    });

    const observer2 = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateWhoWeAreIsVisible(entry.isIntersecting);
    });

    observer.observe(myRef.current);
    observer1.observe(contactRef.current);
    observer2.observe(whoWeAreRef.current);
  }, []);
  return (
    <div className="App">
      <Container maxWidth="xl" disableGutters>
        <header>
          <Box sx={{ height: { sm: 568 } }} ref={myRef}>
            <video
              playsinline="playsinline"
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              id="background-video"
            >
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </Box>

          <Box
            className="overlay"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <div class="video_textBlock">
              <div class="dpl-loop-video__bodyText">
                <h1>
                  <span class="dpl-display-1">
                    {data ? data.videoText : "Loading"}
                  </span>
                </h1>
                <p>{data ? data.videoSubText : "Loading"}</p>
              </div>
            </div>
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" }, p: 2 }}>
            <div>
              <h1>
                <span className="content-1">
                  {data ? data.videoText : "Loading"}
                </span>
              </h1>
              <p className="content-2">
                {data ? data.videoSubText : "Loading"}
              </p>
            </div>
          </Box>
          <Navigation headerIsVisible={headerIsVisible} />
        </header>
        <Box ref={whoWeAreRef}>
          <WhoWeAre whoWeAreIsVisible={whoWeAreIsVisible} id="whoWeAre" />
        </Box>
        <Box>
          <News id="news" />
        </Box>
        <Box ref={contactRef}>
          <Contact contactIsVisible={contactIsVisible} id="contact" />
        </Box>
        {/* <Footer /> */}
      </Container>
    </div>
  );
};

export default Home;
