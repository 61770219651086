import * as React from "react";

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Container from "@mui/material/Container";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import backgroundVideo from "./../coverPageVideo.mp4";
import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ContactMailRoundedIcon from "@mui/icons-material/ContactMailRounded";
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#ebebef",
      contrastText: "#fff",
    },
  },
});

const drawerWidth = "100%";
const navItems = [
  {
    name: "Who We Are",
    to: "whoWeAre",
    icon: <BusinessRoundedIcon sx={{ color: "#002677" }} fontSize="large" />,
  },
  {
    name: "News",
    to: "news",
    icon: <NewspaperRoundedIcon sx={{ color: "#002677" }} fontSize="large" />,
  },
  {
    name: "Contact",
    to: "contact",
    icon: <ContactMailRoundedIcon sx={{ color: "#002677" }} fontSize="large" />,
  },
];

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView();
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Navigation(props) {
  const { window, headerIsVisible, hideMenu } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link
          to="back-to-top-anchor"
          spy={true}
          smooth={true}
          onClick={handleDrawerToggle}
        >
          <RouteLink
            to={{
              pathname: "/",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/deca-food-tech-logo.png"}
              className="logo"
              alt="Deca Tech"
            />
          </RouteLink>
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <Link to={item.to} spy={true} smooth={true} offset={-50}>
              <ListItemButton
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "1000",
                }}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      {/* <ElevationScroll {...props}> */}
      <AppBar
        component="nav"
        color={headerIsVisible ? "transparent" : ""}
        elevation={headerIsVisible ? 0 : 4}
        position={headerIsVisible ? "absolute" : "fixed"}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <RouteLink
                to={{
                  pathname: "/",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/deca-food-tech-logo.png"}
                  class="logo"
                  alt="Deca Tech"
                />
              </RouteLink>
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              {!hideMenu &&
                navItems.map((item) => (
                  <Button
                    key={item.name}
                    sx={{
                      color: headerIsVisible ? "#fff" : "#002677",
                      fontWeight: 1000,
                    }}
                  >
                    <Link to={item.to} spy={true} smooth={true} offset={-50}>
                      {item.name}
                    </Link>
                  </Button>
                ))}
            </Box>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              {!hideMenu && (
                <MenuIcon
                  style={{ color: headerIsVisible ? "#fff" : "#000" }}
                />
              )}
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      {/* </ElevationScroll> */}
      {/* <Toolbar id="back-to-top-anchor" /> */}
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
