import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import Home from "./components/home";
import PrivacyPolicy from "./components/privacyPolicy";
import Security from "./components/security";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";

function App() {
  //const { ref: myref, inView: myElementVisible } = useInView();
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <>
      <div id="back-to-top-anchor" />
      <Routes>
        <Route path="/" element={<Home data={landingPageData.Home} />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/security" element={<Security />} />
        <Route path="*" element={<Home data={landingPageData.Home} />} />
      </Routes>
      <Footer />
    </>
  );
}

// const AppWrapper = () => {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// };

export default App;
