import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Container from "@mui/material/Container";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Slide from "@mui/material/Slide";
import ContactForm from "./contactForm";

const Contact = (props) => {
  const { contactIsVisible, id } = props;
  return (
    <Container maxWidth="lg">
      <Box id={id} sx={{ flexGrow: 1 }} className="contact-box">
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ my: 3 }}>
            <h1 className="contact-heading">Contact </h1>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
            <ContactForm />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
            <Box sx={{ m: { xs: 4, sm: 0 } }}>
              <h3>Deca Food Tech Oy</h3>
              <div>
                <p>CompanyID (Y-tunnus): 3292119-7</p>
              </div>
              <h3>Address</h3>
              <div>
                <p>Kympinkatu 3 C, 40320</p>
                <p>Jyväskylä, Finland</p>
              </div>
            </Box>
            <Box sx={{ m: 4 }}>
              <h3>Contacts</h3>
              <div>
                <p>
                  <strong>Malladi Nagalakshmaiah</strong>
                </p>
                <p>Co-founder</p>
                <p>malladi.n@decafoodtech.com</p>
                <br></br>
                <p>
                  <strong>Anvesh D</strong>
                </p>
                <p>Co-founder</p>
                <p>anvesh.dindu@decafoodtech.com</p>
                <br></br>
                <p>
                  <strong>Katika J</strong>
                </p>
                <p>Development Manager</p>
              </div>
            </Box>
            <Box sx={{ m: 4 }}>
              {/* <a
                href="https://www.facebook.com/"
                target="_blank"
                className="socialMediaLink"
              >
                <FacebookRoundedIcon sx={{ p: 1 }} />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                className="socialMediaLink"
              >
                <TwitterIcon sx={{ p: 1 }} />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                className="socialMediaLink"
              >
                <InstagramIcon sx={{ p: 1 }} />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                className="socialMediaLink"
              >
                <LinkedInIcon sx={{ p: 1 }} />
              </a> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Contact;
