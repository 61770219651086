import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import emailjs from "@emailjs/browser";
import AlertDialog from "./alertDialog";

function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

const ContactForm = () => {
  const [name, setName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [nameError, setErrorName] = React.useState(false);
  const [emailError, setErrorEmail] = React.useState(false);
  const [messageError, setErrorMessage] = React.useState(false);

  const [openEmailAlertPopup, setEmailAlertPopup] = React.useState(false);
  const [emailSentMsg, setEmailSentMsg] = React.useState(false);

  const form = useRef();

  const handleOnNameChange = (event) => {
    setName(event.target.value);
    setErrorName(false);
  };

  const handleOnLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleOnEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorEmail(false);
  };

  const handleOnMessageChange = (event) => {
    setMessage(event.target.value);
    setErrorMessage(false);
  };

  const handleOnSubmit = (event) => {
    setErrorName(name ? false : true);
    setErrorEmail(email ? false : true);
    setErrorMessage(message ? false : true);

    if (email) {
      setErrorEmail(!ValidateEmail(email));
    }

    debugger;
    if (name && email && !emailError && message) {
      emailjs
        .sendForm(
          "service_sgk0m9q",
          "template_bwvc6rs",
          form.current,
          "zrx5fiX9HSrxXp9dp"
        )
        .then(
          (result) => {
            debugger;
            setEmailAlertPopup(true);
            setEmailSentMsg("We recieved your email. Will respond shortly");
          },
          (error) => {
            setEmailAlertPopup(true);
            setEmailSentMsg(
              "Something Wrong. Please directly send us Email or Call"
            );
          }
        );
    }
  };

  const handleOnDialogClose = () => {
    setEmailAlertPopup(false);
    setName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "80%" },
        }}
        noValidate
        autoComplete="off"
      >
        <form ref={form}>
          <TextField
            required
            error={nameError}
            name="user_name"
            size="small"
            id="outlined-name"
            label="Name"
            value={name}
            onChange={handleOnNameChange}
            helperText={nameError ? "Please Enter Name" : ""}
          />
          <TextField
            size="small"
            id="outlined-name"
            label="Last Name"
            value={lastName}
            onChange={handleOnLastNameChange}
          />
          <TextField
            required
            name="user_email"
            error={emailError}
            size="small"
            id="outlined-name"
            label="Your Email"
            value={email}
            onChange={handleOnEmailChange}
            helperText={emailError ? "Please Enter Valid Email" : ""}
          />

          <TextField
            required
            name="message"
            error={messageError}
            id="outlined-multiline-static"
            label="Enter Your Message"
            multiline
            rows={4}
            value={message}
            onChange={handleOnMessageChange}
            helperText={messageError ? "Please Enter Your Message" : ""}
          />
          <Box sx={{ textAlign: "right", width: "90%", mt: 2 }}>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleOnSubmit}
              sx={{ backgroundColor: "#002677" }}
            >
              Send
            </Button>
          </Box>
        </form>
      </Box>
      <AlertDialog
        open={openEmailAlertPopup}
        message={emailSentMsg}
        onClose={handleOnDialogClose}
      />
    </>
  );
};

export default ContactForm;
