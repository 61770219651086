import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  //   padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const News = (props) => {
  return (
    <Container disableGutters maxWidth="xl" sx={{ backgroundColor: "#f8f8f8" }}>
      <Box
        className="section-testimonials"
        sx={{ flexGrow: 1, height: { sm: "568px", xs: "auto" }, width: "100%" }}
        id={props.id}
      >
        {/* <Box>
          <p>Latest news and updates</p>
          <h2 className="section-heading">Stay in the know</h2>
        </Box> */}
        ,
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12}>
            <h2 className="section-news">Latest News</h2>
            {/* </Item> */}
          </Grid>
          {/* <Grid item xs={12} lg={6} sx={{ m: 2 }}>
            <Card sx={{}}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={process.env.PUBLIC_URL + "/img/news2.jpg"}
                  alt="green iguana"
                />
                <CardContent>
                  
                  <div className="news-1">
                    <div className="news-eyebrow">Article</div>
                    <h3 className="news_heading">
                      How the pandemic changed the conversation on mental health
                    </h3>
                    <div className="news__bodycopy">
                      <p>
                        Everyday conversations have changed, including how we
                        talk about our mental health.&nbsp;
                      </p>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}
          <Grid item xs={12} lg={5} sx={{ m: 2 }}>
            <Card sx={{}}>
              <CardActionArea>
                <CardContent>
                  <div className="news-1">
                    <div className="news-eyebrow">Upcoming</div>
                    <h3 className="news_heading">
                      We are building exciting ecosystem of products, keep
                      watching this space for latest updates
                    </h3>
                    <div className="news__bodycopy">
                      <p>Keep Watching...&nbsp;</p>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default News;
