import React from "react";
import Navigation from "./navigation";
import { Box } from "@mui/material";

const Security = () => {
  return (
    <>
      <Navigation hideMenu={true} />
      <Box mt={10} px={5}>
        <h2>Security</h2>
        <div>
          <b>
            Help keep Deca safe for the community by disclosing security issues
            to us
          </b>
          <p>
            We take security seriously at Deca. If you are a security researcher
            or expert, and believe you’ve identified security-related issues
            with Deca’s website or apps, we would appreciate you disclosing it
            to us responsibly.
          </p>
          <p>
            Our team is committed to addressing all security issues in a
            responsible and timely manner, and ask the security community to
            give us the opportunity to do so before disclosing them publicly.
            Please send an email to <b>anvesh.dindu@decafoodtech.com</b>, along
            with a detailed description of the issue and steps to reproduce it,
            if any. We trust the security community to make every effort to
            protect our users data and privacy.
          </p>
        </div>
      </Box>
    </>
  );
};

export default Security;
